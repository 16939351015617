<template>
  <div>
    <BaseForm
      :title="title"
      :mod="mod"
      :flat="flat"
      :divider="divider"
      :dialog="dialog"
      :loading="loading"
      @save="save"
    >
      <template #content>
        <v-row>
          <v-col
            ><v-select
              label="Perfil de usuário"
              :loading="loadingProfiles"
              :items="userProfiles"
              v-model="form.UserProfileId"
              class="required"
            >
            </v-select
          ></v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              hide-details
              label="Nome"
              v-model="form.firstName"
              class="required"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              hide-details
              label="Sobrenome"
              v-model="form.lastName"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            ><v-text-field
              hide-details
              label="E-mail"
              v-model="form.email"
              class="required"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-col
            ><v-text-field
              hide-details
              label="CPF"
              v-model="form.taxDocument"
              v-mask="'###.###.###-##'"
              class="required"
            ></v-text-field
          ></v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              hide-details
              type="password"
              label="Senha"
              v-model="form.password"
              class="required"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              hide-details
              type="password"
              label="Confirmar senha"
              v-model="form.confirmPassword"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template #actions></template>
    </BaseForm>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  components: {
    BaseForm,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    loadingProfiles: false,
    form: { type: "physical-person" },
    userProfiles: [],
    actionAfterOperation: null,
  }),
  computed: {},
  methods: {
    validate() {
      const errors = [];

      // if (!this.form.UserProfileId)
      //   errors.push("O campo PERFIL DE USUÁRIO é obrigatório");

      if (!this.form.firstName) errors.push("O campo NOME é obrigatório");

      if (!this.form.lastName) errors.push("O campo SOBRENOME é obrigatório");

      if (!this.form.taxDocument) errors.push("O campo CPF é obrigatório");

      if (!this.form.type) errors.push("O campo TIPO DE PESSOA é obrigatório");

      if (this.mod === "insert" && !this.form.password)
        errors.push("O campo SENHA é obrigatório");

      if (!this.form.email) errors.push("O campo E-MAIL é obrigatório");

      if (
        this.form.password &&
        this.form.password !== this.form.confirmPassword
      )
        errors.push("As senhas não conferem");

      return errors;
    },
    async save(actionAfterOperation) {
      if (
        !(await this.$root.$confirm("Confirmação", "Salvar dados?", {
          color: "primary",
        }))
      )
        return;

      const errors = this.validate();

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this.actionAfterOperation = actionAfterOperation;
        this[this.mod]();
      }
    },
    async insert() {
      try {
        this.loading = true;

        await this.$axios.post(`/users`, this.form);

        this.clearForm();

        this.$toast.success("Dados salvos com sucesso");

        this.afterOperation();
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        await this.$axios.put(`/users/${this.$route.params.id}`, this.form);

        this.$toast.success("Dados salvos com sucesso");

        this.afterOperation();
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },

    async loadUserData() {
      try {
        this.loadingPermissions = true;

        const response = await this.$axios.get(
          `/users/${this.$route.params.id}`
        );

        this.setUserData(response.data);
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingPermissions = false;
      }
    },
    setUserData(data) {
      const cpData = { ...data };

      this.form = cpData;
    },
    afterOperation() {
      if (this.actionAfterOperation === "back") this.$router.go(-1);
    },
    clearForm() {
      this.form = { type: "physical-person" };
    },
    async loadProfiles() {
      try {
        this.loadingProfiles = true;

        let url = `/users/profiles`;

        const response = await this.$axios.get(url);

        this.setProfiles(response.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingProfiles = false;
      }
    },
    setProfiles(data) {
      const cpData = [...data];

      this.userProfiles = cpData.map((p) => ({ text: p.name, value: p.id }));
    },
  },
  created() {
    this.loadProfiles();
    if (this.mod === "update") this.loadUserData();
  },
};
</script>

<style></style>
